<template>
  <div v-if="allowAccess" class="project-wrapper" style="margin-bottom: 178px;">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row class="row-space-16 settings-header">
      <div class="page-navigation"><span @click="goMainPage" class="project-overview-link"><img style="margin-right: 8px;" :src="require('../../../assets/arrowLeft.svg')"/><span style="border-bottom: 1px solid #1990FF;">Project overview</span></span><span><img style="margin-right: 8px;" :src="require('../../../assets/arrowLeft-grey.svg')"/>{{dashboardName}}</span></div>
      <div class="management-title">Dashboard Management
        <span v-if="openSetting != 'mydataroom'" class="updated-on" style="float: right;" >
          Last updated on: {{ settingsLastUpdatedAt }}
        </span>
      </div>
    </a-row>
    <a-row class="main-area">
      <a-col class="settings-menu">
        <a-row class="row-space-24">
          <div v-bind:class="{ 'dashboard-overview-menu': showHeader, 'dashboard-overview-menu-full': !showHeader, }" >
            <a-menu
              mode="inline"
              :open-keys="openKeys"
              :default-selected-keys="defaultMenuKeys"
              :key="defaultMenuKeys"
              @openChange="onOpenChange"
              @click="handleClick"
            >
              <a-menu-item key="1">
                  <span>Dashboard Overview</span>
              </a-menu-item>
              <a-menu-item key="2">
                  <span>My Tasks<span v-if="hasOpenSteps" class="dot"></span></span>
              </a-menu-item>
              <!--<a-menu-item key="3">
                  <span>My Data Room</span>
              </a-menu-item>-->
            </a-menu>
            <img v-if="openSetting === 'overview'" style="position: absolute; left: 16px; bottom: 16px;" :src="require('../../../assets/setupstepicon/dashboardoverviewVisual.svg')"/>
            <img v-if="openSetting === 'mydataroom'" style="position: absolute; left: 16px; bottom: 16px;" :src="require('../../../assets/setupstepicon/dataroomVisual.svg')"/>
            <img v-if="openSetting === 'mytasks'" style="position: absolute; left: 2px; bottom: 16px;" :src="require('../../../assets/setupstepicon/setupstepsVisual.svg')"/>
          </div>
        </a-row>
      </a-col>
      <a-col class="content">
        <span v-if="openSetting === 'overview'"><DashboardOverview @show-header="toggleHeader"/></span>
        <span v-if="openSetting === 'mytasks'"><DashboardMyTasks @check-for-open-task="checkForOpenTask"/></span>
        <!--<span v-if="openSetting === 'mydataroom'"><DashboardMyDataRoom/></span>-->
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DashboardOverview from './DashboardOverview.vue';
import DashboardMyTasks from './DashboardMyTasks.vue';
// import DashboardMyDataRoom from './DashboardMyDataRoom.vue';

import { auth, db } from '../../../firebase';
import { path } from 'ramda';

export default {
  name: 'DashboardSettings',
  data() {
    return {
      showHeader: true,
      overlay: false,
      dashboardName: '',
      openSetting: 'overview',
      dashboardType: '',
      hasOpenSteps: false,
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: ['sub1'],
      defaultMenuKeys: ['1'],
      dashmoteUser: false,
      allowAccess: true,
      settingsLastUpdatedAt: 'Unavailable',
    };
  },
  components: {
    DashboardOverview,
    DashboardMyTasks,
    // DashboardMyDataRoom,
  },
  methods: {
    toggleHeader(showHeader) {
      this.showHeader = showHeader;
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find((key) => !this.openKeys.includes(key));
      this.openKeys = this.rootSubmenuKeys.includes(latestOpenKey) ? [latestOpenKey] : openKeys;
    },
    checkForOpenTask() {
      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}`
      ).once('value', (snapshot) => {
        const settings = snapshot.val()?.settings;
        this.hasOpenSteps = Object.values(settings?.setupsteps || {}).some(
          (step) => step.status === 'open'
        );
      });
    },
    goMainPage() {
      this.$router.push(`/project/${this.$route.params.projectId}`);
    },
    getData() {
      db.ref(
        `projects/${this.$route.params.projectId}/dashboards/${this.$route.params.dashboardId}`
      ).once('value', (snapshot) => {
        const dashboard = snapshot.val();
        this.dashboardType = dashboard?.productLineValue;
        this.dashboardName = dashboard?.name;
        this.settingsLastUpdatedAt = dashboard?.settings?.lastUpdatedAt || '28-06-2021';
      });
    },
    handleClick(e) {
      switch (e.key) {
        case '1':
          this.$mixpanel.track('Open Dashboard Overview', { dashboard: this.dashboardName });
          this.openSetting = 'overview';
          break;
        case '2':
          this.$mixpanel.track('Open My Tasks', { dashboard: this.dashboardName });
          this.openSetting = 'mytasks';
          break;
        case '3':
          this.$mixpanel.track('Open Dataroom', { dashboard: this.dashboardName });
          this.openSetting = 'mydataroom';
          break;
      }
    },
    checkPermission() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          db.ref(`users/${user.uid}/projects/${this.$route.params.projectId}`)
            .once('value', (snapshot) => {
              if (snapshot.val() || this.dashmoteUser) {
                this.allowAccess = true;
              } else {
                console.log(
                  'User has no access to these settings because they are not invited to the project'
                );
                this.allowAccess = false;
              }
            })
            .catch((error) => console.error('Error checking permission:', error));
        } else {
          console.error('No user is currently logged in.');
          this.allowAccess = false;
        }
      });
    },
    checkIsDashmoteUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.dashmoteUser = user.email.endsWith('@dashmote.com');
        } else {
          console.error('No user is currently logged in.');
        }
      });
    },
  },
  mounted() {
    this.$mixpanel.track('Open Dashboard Management', { dashboard: this.dashboardName });
    this.checkIsDashmoteUser();
    this.checkPermission();
    this.getData();
    this.checkForOpenTask();

    if (this.$route.params.page === 'tasks') {
      this.defaultMenuKeys = ['2'];
      this.openSetting = 'mytasks';
    }
  },
};
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
