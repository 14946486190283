<template>
   <a-row class="main-area">
      <a-col class="content">
         <a-row class="row-space-24">
            <div v-if="showHeader" class="dashboard-overview-header overview-main-header-right">
              <!-- Hide clouds for now
               <div style="position: absolute; width: calc(100% - 37px); height: 130px; overflow: hidden; z-index: 0;">
                  <div style="margin-top: 50px;" class="x1">
                     <div class="cloud"></div>
                  </div>
                  <div class="x2">
                     <div class="cloud"></div>
                  </div>
                  <div class="x3">
                     <div class="cloud"></div>
                  </div>
               </div> -->
               <div style="position: absolute; overflow: hidden; height: 130px; right: 8px;">
                  <img @click="closeHeader" style="position: absolute; right:8px; margin-top:16px; cursor: pointer;" :src="require('../../../assets/setupstepicon/closeIcon.svg')"/>
                  <img style="margin-top:-80px;" :src="require('../../../assets/setupstepicon/headerImage.svg')"/>
               </div>
               <div class="dashboard-overview-header-content">
                  <div class="title">
                     <img style="margin-right: 8px;" :src="require('../../../assets/setupstepicon/information.svg')"/>Welcome to your dashboard info!
                  </div>
                  <div class="content">
                     <span>Find scope information, track the project process,</span><br/>
                     <span> and exchange files with our team!</span>
                  </div>
               </div>
            </div>
            <div v-bind:class="{ 'dashboard-overview-content': showHeader, 'dashboard-overview-content-full': !showHeader }" class="overview-main-content-right">
               <div class="dashboard-overview-inner-content">
                  <v-md-preview style="margin-left: 0px; margin-top: 0px;" :text="overviewContent"></v-md-preview>
               </div>
            </div>
         </a-row>
      </a-col>
   </a-row>
</template>

<script>
import { db } from '../../../firebase'

export default {
  name: 'DashboardOverview',
  data () {
    return {
      overlay: false,
      showHeader: true,
      overviewContent: ''
    }
  },
  methods: {
    closeHeader () {
      if (this.showHeader) {
        this.showHeader = false
      }
      this.$emit('show-header', false)
    },
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    getData () {
      const self = this
      db.ref('projects/' + self.$route.params.projectId + '/dashboards/' + self.$route.params.dashboardId).once('value', snapshot => {
        if (snapshot.val().settings) {
          self.overviewContent = snapshot.val().settings.overviewContent
        }
      })
    },
    handleClick (e) {
      switch (e.key) {
        case '1':
          console.log('Dashboard Overview Click')
          this.$router.push(`/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings/overview`)
          break
        //case '2':
        //  console.log('My Data Room Click')
        //  this.$router.push(`/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings/mydataroom`)
        //  break
        case '3':
          console.log('Brand List Click')
          this.$router.push(`/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings/setupsteps`)
          break
        case '4':
          console.log('Franchise List Click')
          break
      }
    }
  },
  mounted () {
    if (this.showHeader) {
      this.$emit('show-header', true)
    } else {
      this.$emit('show-header', false)
    }
    this.getData()
  }
}
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
